import * as React from "react";
import { graphql } from "gatsby";
import { Seo } from "../components/Seo";
import { Header, Footer, PressLayout } from "../components/layout/index";
import { BannerImage } from "../components/atoms/BannerImage";

export default function Press({ data }) {
  const { posts } = data;
  return (
    <>
      <Seo title="Presse" />
      <Header />
      <PressLayout posts={posts} />
      <BannerImage />
      <Footer />
    </>
  );
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    posts: allSanityPresse(sort: { fields: publishedAt, order: DESC }) {
      nodes {
        id
        title
        link
        category
        publishedAt(formatString: "DD MMMM YYYY", locale: "fr")
      }
    }
  }
`;
